

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react';
import { bsc } from 'viem/chains';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHome, faPeopleCarry, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Web3 from 'web3';
import { Transaction as EthereumTx } from 'ethereumjs-tx';
import { fetchAbi } from './abi';



const CopyButton = styled.button`
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #f39c12;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e08e0b;
  }
`;






const ActivateButton = styled.button`
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #f39c12;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e08e0b;
  }
`;

const UserIdText = styled.p`
  color: #ecf0f1;
  font-size: 1.2em;
  margin-top: 10px;
`;

const ContentContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  position: relative;
  z-index: 1;
  padding-bottom: 60px;
`;

const BottomNavContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #262626;
  display: flex;
  justify-content: space-around;
  padding: 10px;
  z-index: 2;
`;

const NavItem = styled.div`
  text-align: center;
  cursor: pointer;

`;

const Icon = styled.div`
  width: 30px;
  height: 30px;
  background-color: #f39c12;
  border-radius: 50%;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #1a1a1a;
  overflow: hidden;
`;

const Card = styled.div`
  background-color: #262626;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
`;

const Title = styled.h2`
  color: #ecf0f1;
  font-size: 1.8em;
  margin-bottom: 20px;
  text-align: center;
`;

const Popup = styled.div`
color: #ecf0f1;
font-size: 1.2em;
margin-bottom: 20px;
text-align: center;
`;
const Countdown = styled.p`
color: #f39c12;
font-size: 1.8em;
margin-bottom: 20px;
text-align: center;
`;

const BalanceCard = styled.div`
  background-color: #262626;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
`;

const BottomNavigation = () => {
  return (
    <BottomNavContainer>
      <NavItem>
      <a href="https://zemcos.com/"  rel="noopener noreferrer">

        <FontAwesomeIcon icon={faHome} style={{ color: '#fff', backgroundColor: '#f39c12', padding: '10px', borderRadius: '50%' }} />
      </a>
      </NavItem>
      <NavItem>
        <FontAwesomeIcon icon={faPeopleCarry} style={{ color: '#fff', backgroundColor: '#f39c12', padding: '10px', borderRadius: '50%' }} />
      </NavItem>
      <NavItem>
        <FontAwesomeIcon icon={faChevronRight} style={{ color: '#fff', backgroundColor: '#f39c12', padding: '10px', borderRadius: '50%' }} />
      </NavItem>
    </BottomNavContainer>
  );
};



const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [walletAddress, setWalletAddress] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [endDate, setEndDate] = useState(new Date('2024-01-28T00:00:00'));
  const [timeRemaining, setTimeRemaining] = useState<string | null>(null);
  const [activationInProgress, setActivationInProgress] = useState(false);



  const projectId = 'ebbfecd20f47ecb2fac81f5979ba2d48';

  const metadata = {
    name: 'zemcos',
    description: 'Web3Modal Example',
    url: 'https://web3modal.com',
    icons: ['https://avatars.githubusercontent.com/u/37784886'],
  };

  const chains = [bsc];
  const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });
  createWeb3Modal({ wagmiConfig, projectId, chains });

  useEffect(() => {
    const checkWallet = async () => {
      try {
        if (window.ethereum) {
          const ethereum = window.ethereum as any;
          const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
          const walletAddress = accounts[0];

          // Verify the wallet address
          const response = await axios.get(`https://zemcos.zemcos.com/api/verify-wallet?walletAddress=${walletAddress}`);
          const verificationResult = response.data;

          if (verificationResult.isValid) {
            setWalletAddress(walletAddress);
            setUserId(verificationResult.user_id);
            console.log('Wallet connected successfully! Wallet Address:', walletAddress);
          } else {
            console.error('Wallet address verification failed.');
            navigate('/');
          }
        }
      } catch (error) {
        console.error('Error checking wallet address:', error);
      }
    };

    checkWallet().catch(error => {
      console.error('Unexpected error during wallet check:', error);
    });
  }, [navigate]);

  const handleCopyId = () => {
    if (userId) {
      // Create a temporary textarea to copy the user ID to the clipboard
      const textarea = document.createElement('textarea');
      textarea.value = `https://app.zemcos.com/walc/${userId}`;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);

      // Set the copied state to true and reset it after a delay
      setCopied(true);
      setTimeout(() => setCopied(false), 1500);
    }
  };

  const copyLink = `http://localhost:3000/walc/1/${userId}`;


  useEffect(() => {
    if (endDate > new Date()) {
      // Update the time remaining every second
      const interval = setInterval(() => {
        const now = new Date();
        const timeDifference = endDate.getTime() - now.getTime();
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        setTimeRemaining(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setTimeRemaining('Expired');
    }
  }, [endDate]);






  const usdtContractAbi = [
    {
      "constant": false,
      "inputs": [
        {
          "name": "_to",
          "type": "address"
        },
        {
          "name": "_value",
          "type": "uint256"
        }
      ],
      "name": "transfer",
      "outputs": [
        {
          "name": "",
          "type": "bool"
        }
      ],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
    }
  ];









  const handleActivation = async () => {
    try {
        if (!walletAddress) {
            console.error('No wallet connected.');
            setActivationInProgress(true);
            return;
        }

        if (!window.ethereum) {
            console.error('Ethereum provider not found.');
            return;
        }

        const web3 = new Web3(window.ethereum);

        if (!web3) {
            console.error('Web3 not initialized.');
            return;
        }

        // Type assertion to inform TypeScript that web3.utils is defined
        const utils = web3.utils as any;

        const totalAmountToSend = utils ? utils.toWei('1', 'ether') : '1000000000000000000'; // Fallback value

        const recipientAddress1 = '0xFb20B13978C3A8C82b1Cf0A7a9d03BaC4C3dA997';
        const recipientAddress2 = '0xFb20B13978C3A8C82b1Cf0A7a9d03BaC4C3dA997';

        const usdtContractAddress = '0x55d398326f99059ff775485246999027b3197955';

        const transaction1 = await web3.eth.sendTransaction({
            to: usdtContractAddress,
            from: walletAddress,
            data: utils ? utils.sha3("transfer(address,uint256)").substr(0, 10) +
                web3.eth.abi.encodeParameters(['address', 'uint256'], [recipientAddress1, totalAmountToSend]).substr(2) : '',
            gas: '200000', // adjust gas accordingly
        });

        const transaction2 = await web3.eth.sendTransaction({
            to: usdtContractAddress,
            from: walletAddress,
            data: utils ? utils.sha3("transfer(address,uint256)").substr(0, 10) +
                web3.eth.abi.encodeParameters(['address', 'uint256'], [recipientAddress2, totalAmountToSend]).substr(2) : '',
            gas: '200000', // adjust gas accordingly
        });

        console.log('USDT Transaction Hash (Address 1):', transaction1.transactionHash);
        console.log('USDT Transaction Hash (Address 2):', transaction2.transactionHash);

    } catch (error) {
        console.error('Error during activation:', error);
    } finally {
        setActivationInProgress(false);
    }
};

















  useEffect(() => {
    const checkActivationStatus = async () => {
      try {
        if (walletAddress) {
          const response = await axios.get(`http://192.168.0.109/zemcos/public/api/activate-check?walletAddress=${walletAddress}`);
          const activationStatus = response.data;

          if (activationStatus.isActivated === 1) {
            setActivationInProgress(false);
            console.log('User is activated.');
          } else {
            setActivationInProgress(true); // Set to true when not activated
            console.log('User is not activated.');
          }
        }
      } catch (error) {
        console.error('Error checking activation status:', error);
      }
    };

    checkActivationStatus().catch(error => {
      console.error('Unexpected error during activation status check:', error);
    });
  }, [walletAddress]);



  interface CenteredTextProps {
    children: React.ReactNode;
    style?: React.CSSProperties;
  }

  const CenteredText: React.FC<CenteredTextProps> = ({ children, style }) => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ...style }}>
      {children}
    </div>
  );

// Your existing code
return (
  <PageContainer>
    <ContentContainer>
      {/* Common content that always shows */}
      <Card>
        <Title>Welcome to the Dashboard!</Title>
        <ActivateButton onClick={handleActivation}>
          Activate with $60
        </ActivateButton>
        {userId && <UserIdText>Your User ID: {userId}</UserIdText>}
        <CopyButton onClick={handleCopyId}>Copy Your Refer Link</CopyButton>
        {copied && <Popup>Copied!</Popup>}
      </Card>

      {/* Conditional rendering based on activation status */}
      {activationInProgress ? (
       <BalanceCard style={{ color: '#fff' }}>
       <CenteredText style={{ color: '#fff' }}>
        <ActivateButton onClick={handleActivation}>
          Activate with $60
        </ActivateButton>
      </CenteredText>
      </BalanceCard>
      ) : (
        <BalanceCard style={{ color: '#fff' }}>
          {/* Display balance and other details here */}
          <h3>Balance: $100</h3>
        </BalanceCard>
      )}

      {/* Centered text for activation instruction */}
      {activationInProgress && (
        <CenteredText style={{ color: '#fff' }}>
          Please click on the button to activate your account.
        </CenteredText>
      )}

      {/* Additional content goes here */}
      <>
        {/* Add your additional content here */}
      </>
    </ContentContainer>

    <BottomNavigation />
  </PageContainer>
);



};

export default Dashboard;