import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react';
import { bsc } from 'viem/chains';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPeopleCarry, faChevronRight } from '@fortawesome/free-solid-svg-icons';


// Define styled components
const MatrixPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #1a1a1a;
  overflow: hidden;
`;

const MatrixNavBar = styled.div`
  background-color: #262626;
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
`;


const MatrixNavLink = styled(Link)`
  color: #fff;
  margin: 0 10px;
  text-decoration: none;
  font-size: 1.5em;

  &:hover {
    text-decoration: underline;
  }
`;

const BottomNavContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #262626;
  display: flex;
  justify-content: space-around;
  padding: 10px;
  z-index: 2;
`;

const NavItem = styled.div`
  text-align: center;
  cursor: pointer;
`;




const MatrixContent = styled.div`
  color: #ecf0f1;
  font-size: 1.5em;
  margin-bottom: 20px; /* Add margin for spacing between content and points */
`;

const MatrixCard = styled.div`
  background-color: #262626;
  padding: 60px; /* Increased padding for a larger card */
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  width: 65%;
`;

const RoundPointContainer = styled.div`
  display: flex;
  width: 100%; /* Make round points take up full width */
  justify-content: space-around; /* Distribute round points evenly */
  margin-top: 10px;
  flex-wrap: wrap;
  gap: 10px;
`;


const RoundPoint = styled.div`
  width: 30px;
  height: 30px;
  background-color: #f39c12;
  border-radius: 50%;
  margin-right: 10px;
  margin-bottom: 10px; /* Adjust margin bottom if needed */
`;

const LoadingIndicator = styled.div`
  color: #fff;
  font-size: 28px;
  padding: 10px;
  border-radius: 5px;
`;













const BottomNavigation = () => {
  return (
    <BottomNavContainer>
      <NavItem>
        <a href="https://zemcos.com/" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faHome} style={{ color: '#fff', backgroundColor: '#f39c12', padding: '10px', borderRadius: '50%' }} />
        </a>
      </NavItem>
      <NavItem>
        <FontAwesomeIcon icon={faPeopleCarry} style={{ color: '#fff', backgroundColor: '#f39c12', padding: '10px', borderRadius: '50%' }} />
      </NavItem>
      <NavItem>
        <FontAwesomeIcon icon={faChevronRight} style={{ color: '#fff', backgroundColor: '#f39c12', padding: '10px', borderRadius: '50%' }} />
      </NavItem>
    </BottomNavContainer>
  );
};

const Matrix: React.FC = () => {
    const [walletAddress, setWalletAddress] = useState<string | null>(null);
    const [userId, setUserId] = useState<string | null>(null);
    const navigate = useNavigate();
    const [userData, setUserData] = useState<UserData[]>([]); // State to store user data
    const [loading, setLoading] = useState(true); // State for loading indicator
    const [secondGenReferrals, setSecondGenReferrals] = useState<ReferralData[]>([]);
    const [thirdGenReferrals, setThirdGenReferrals] = useState<ReferralData[]>([]);
    const [fourthGenReferrals, setFourthGenReferrals] = useState<ReferralData[]>([]);


    useEffect(() => {
      const checkWallet = async () => {
        try {
          if (window.ethereum) {
            const ethereum = window.ethereum as any;
            const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
            const walletAddress = accounts[0];

            const response = await axios.get(`http://192.168.0.109/zemcos/public/api/verify-wallet?walletAddress=${walletAddress}`);
            const verificationResult = response.data;

            if (verificationResult.isValid) {
              setWalletAddress(walletAddress);
              setUserId(verificationResult.user_id);
              console.log('Wallet connected successfully! Wallet Address:', walletAddress);

              // Make an API call to verify the referral code
              const referralCode = verificationResult.user_id.toString();
              console.log('Referral Code:', referralCode); // Log the referral code for debugging

              const referralResponse = await axios.get(`http://192.168.0.109/zemcos/public/api/verify-referral-code/${referralCode}`);
              const referralCount = referralResponse.data.referralCount;
              const user_id = referralResponse.data.user_id;
              const created_at = referralResponse.data.created_at;
              const activated = referralResponse.data.activated;

              console.log('Referral code is valid! Referral count:', referralCount);
              console.log('User ID:', user_id);
              console.log('Created At:', created_at);
              console.log('Activated:', activated);
            } else {
              console.error('Wallet address verification failed.');
              navigate('/');
            }
          }
        } catch (error) {
          console.error('Error checking wallet address:', error);
        }
      };

      checkWallet().catch(error => {
        console.error('Unexpected error during wallet check:', error);
      });

    }, [navigate]);





   // Define an interface for the user data structure
interface UserData {
    referralCount: number;
    user_id: number;
    created_at: string; // You might need to adjust the type based on your actual data
    activated: number; // Adjust the type as needed
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Set loading to true when fetching data

        // Simulate API call to fetch user data
        await new Promise(resolve => setTimeout(resolve, 3000)); // Wait for 3 seconds

        if (!userId) {
          console.error('User ID is not available yet. Waiting...');
          setLoading(false); // Set loading to false
          return;
        }

        const referralCode = userId.toString(); // Convert to string if needed
        console.log('Referral Code:', referralCode);

        // Make an API call to get user data
        const response = await axios.get(`http://192.168.0.109/zemcos/public/api/verify-referral-code/${referralCode}`);

        // Define the type of userData using the UserData interface
        const userData = response.data;
        setUserData(userData);

        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error('Error checking referral code:', error);
        setLoading(false); // Set loading to false on error
      }
    };

    fetchData().catch(error => {
      console.error('Unexpected error during referral code check:', error);
    });
  }, [userId]);





 // Define an interface for the structure of each referral data object
interface ReferralData {
    id: number;
    user_id: number;
    package_data: string;
    refer_code: string;
    wallet_address: string;
    parsed_package_id: string;
    created_at: string;
    updated_at: string;
    activated: number;
}
interface SecondGenReferral {
    id: number;
    activated: number; // Add the activated property here
    // Other properties...
}

interface ReferralData {
    second_gen_referrals: SecondGenReferral[];
    // Other fields in ReferralData
}
useEffect(() => {
    const fetchSecondGenReferrals = async () => {
        try {
            setLoading(true);

            if (!userId) {
                console.error('User ID is not available yet. Waiting...');
                setLoading(false);
                return;
            }

            const referralCode = userId.toString();
            console.log('Referral Code:', referralCode);

            const response = await axios.get(`http://192.168.0.109/zemcos/public/api/verify-referral-code/${referralCode}/second-gen-referrals`);

            // Cast the response data to the type of ReferralData[]
            const fetchedReferrals: ReferralData[] = response.data;
            console.log('Second Generation Referrals:', fetchedReferrals);

            fetchedReferrals.forEach((referralData, index) => {
                referralData.second_gen_referrals.forEach((secondGenReferral, subIndex) => {
                    console.log(`Referral ID ${index + 1}.${subIndex + 1}: ${secondGenReferral.id}`);
                });
            });




            setSecondGenReferrals(fetchedReferrals);
            setLoading(false);
        } catch (error) {
            console.error('Error checking second-generation referrals:', error);
            setLoading(false);
        }
    };

    fetchSecondGenReferrals().catch(error => {
        console.error('Unexpected error during second-generation referral check:', error);
    });
}, [userId]);




interface ReferralData {

    id: number;
    // Define properties common to all referral levels
    // Define properties specific to each referral level
    third_gen_referrals: ThirdGenReferral[];
    fourth_gen_referrals: FourthGenReferral[]; // Define the structure of fourth-gen referrals
}


interface ThirdGenReferral {
    // Define other properties of ThirdGenReferral
    id: string;
    // Include the activated property
    activated: number; // Adjust the type as per your data
    // Add other properties as needed
}



useEffect(() => {
    const fetchThirdGenReferrals = async () => {
        try {
            setLoading(true);

            if (!userId) {
                console.error('User ID is not available yet. Waiting...');
                setLoading(false);
                return;
            }

            const referralCode = userId.toString();
            console.log('Referral Code:', referralCode);

            const response = await axios.get(`http://192.168.0.109/zemcos/public/api/verify-referral-code/${referralCode}/third-gen-referrals`);

            // Cast the response data to the type of ReferralData[]
            const fetchedReferrals: ReferralData[] = response.data;
            console.log('Third Generation Referrals:', fetchedReferrals);

            fetchedReferrals.forEach((referralData, index) => {
                referralData.third_gen_referrals.forEach((thirdGenReferral, subIndex) => {
                    console.log(`Referral ID ${index + 1}.${subIndex + 1}: ${thirdGenReferral.id}`);
                });
            });

            setThirdGenReferrals(fetchedReferrals);
            setLoading(false);
        } catch (error) {
            console.error('Error checking third-generation referrals:', error);
            setLoading(false);
        }
    };

    fetchThirdGenReferrals().catch(error => {
        console.error('Unexpected error during third-generation referral check:', error);
    });
}, [userId]);




interface ThirdGenReferral {
    id: string;
    activated: number;
}

interface FourthGenReferral {
    id: string;
    activated: number;
}


useEffect(() => {
    const fetchFourthGenReferrals = async () => {
        try {
            setLoading(true);

            if (!userId) {
                console.error('User ID is not available yet. Waiting...');
                setLoading(false);
                return;
            }

            const referralCode = userId.toString();
            console.log('Referral Code:', referralCode);

            const response = await axios.get<ReferralData[]>(`http://192.168.0.109/zemcos/public/api/verify-referral-code/${referralCode}/fourth-gen-referrals`);

            const fetchedReferrals = response.data;
            console.log('Fourth Generation Referrals:', fetchedReferrals);

            setFourthGenReferrals(fetchedReferrals);
            setLoading(false);
        } catch (error) {
            console.error('Error checking fourth-generation referrals:', error);
            setLoading(false);
        }
    };

    fetchFourthGenReferrals().catch(error => {
        console.error('Unexpected error during fourth-generation referral check:', error);
    });
}, [userId]);







  return (
    <><MatrixPageContainer>
          <>
              <br />
          </>
          <MatrixContent>User ID: {userId}</MatrixContent>
          <MatrixContent>Level 1</MatrixContent>

          <MatrixCard>
              <div>
                  {loading ? (
                      <LoadingIndicator>Loading...</LoadingIndicator>
                  ) : (
                      <>
                          <RoundPointContainer className="roundPointContainer">
                              {userData.map((user, index) => (
                                  user.activated === 1 ? <RoundPoint key={index} className="roundPoint" /> : null
                              ))}
                          </RoundPointContainer>
                      </>
                  )}
              </div>

          </MatrixCard>


          <MatrixContent>Level 2</MatrixContent>

          <MatrixCard>
          <RoundPointContainer className="roundPointContainer">
  {loading ? ( // Check if loading is true
    <LoadingIndicator>Loading...</LoadingIndicator>
  ) : (
    secondGenReferrals.map((referralSet, index) => (
      // Map over each referral set
      referralSet.second_gen_referrals.length > 0 && (
        <React.Fragment key={index}>
          {/* Map over each referral ID within the referral set */}
          {referralSet.second_gen_referrals.map((referral, subIndex) => (
            // Check if activated is equal to 1
            referral.activated === 1 && (
              // Render the round point for each referral ID
              <RoundPoint key={`${index}-${subIndex}`} className="roundPoint" />
            )
          ))}
        </React.Fragment>
      )
    ))
  )}
</RoundPointContainer>










              <MatrixContent>









              </MatrixContent>
          </MatrixCard>


          <MatrixContent>Level 3</MatrixContent>

<MatrixCard>

<RoundPointContainer className="roundPointContainer">
  {loading ? (
    <LoadingIndicator>Loading...</LoadingIndicator>
  ) : (
    thirdGenReferrals.map((referralSet, index) => (
      referralSet.third_gen_referrals.length > 0 && (
        <React.Fragment key={index}>
          {referralSet.third_gen_referrals.map((referral, subIndex) => (
            // Check if activated exists and is equal to 1
            referral.activated && referral.activated === 1 && (
              <RoundPoint key={`${index}-${subIndex}`} className="roundPoint" />
            )
          ))}
        </React.Fragment>
      )
    ))
  )}
</RoundPointContainer>


</MatrixCard>


<MatrixContent>Level 4</MatrixContent>

<MatrixCard>
  <RoundPointContainer className="roundPointContainer">
    {loading ? (
      <LoadingIndicator>Loading...</LoadingIndicator>
    ) : (
      fourthGenReferrals.map((referralSet, index) => (
        referralSet.fourth_gen_referrals.length > 0 && (
          <React.Fragment key={index}>
            {referralSet.fourth_gen_referrals.map((referral, subIndex) => (
              // Check if activated exists and is equal to 1
              referral.activated && referral.activated === 1 && (
                <RoundPoint key={`${index}-${subIndex}`} className="roundPoint" />
              )
            ))}
          </React.Fragment>
        )
      ))
    )}
  </RoundPointContainer>
</MatrixCard>



          <BottomNavigation />
      </MatrixPageContainer><>
      <br />
      <br />
      <br />
          </></>
  );
};

export default Matrix;